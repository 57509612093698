import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94')
];

export const server_loads = [11,12,9,14,15,3,4,5,6,8];

export const dictionary = {
		"/(corporate)": [~61,[11]],
		"/(corporate)/annonsera": [~62,[11]],
		"/(corporate)/appen": [~63,[11]],
		"/(corporate)/biljettforsaljning": [64,[11]],
		"/(corporate)/blogg": [65,[11,12]],
		"/(corporate)/blogg/lista/[categoryId]": [67,[11,12]],
		"/(corporate)/blogg/[slug]/[id]": [~66,[11,12]],
		"/(components)/components": [26,[9]],
		"/(components)/components/advanced-register-filter": [31,[9]],
		"/(components)/components/alert": [32,[9]],
		"/(components)/components/badge": [33,[9]],
		"/(components)/components/button": [34,[9]],
		"/(components)/components/checkbox": [35,[9]],
		"/(components)/components/cookie": [36,[9]],
		"/(components)/components/date-picker": [37,[9]],
		"/(components)/components/dialog": [38,[9]],
		"/(components)/components/drawer": [39,[9]],
		"/(components)/components/dropdown": [40,[9]],
		"/(components)/components/examples": [41,[9]],
		"/(components)/components/group-selector": [42,[9]],
		"/(components)/components/input": [43,[9]],
		"/(components)/components/mail-preview": [44,[9]],
		"/(components)/components/member-selector": [46,[9]],
		"/(components)/components/member": [45,[9]],
		"/(components)/components/modal": [47,[9]],
		"/(components)/components/newstag": [48,[9]],
		"/(components)/components/pagination": [49,[9]],
		"/(components)/components/personal-number-label": [50,[9]],
		"/(components)/components/pill": [51,[9]],
		"/(components)/components/popover": [52,[9]],
		"/(components)/components/popup-box": [53,[9]],
		"/(components)/components/select": [54,[9]],
		"/(components)/components/table": [55,[9]],
		"/(components)/components/tabs": [56,[9]],
		"/(components)/components/test": [57,[9]],
		"/(components)/components/textarea": [58,[9]],
		"/(components)/components/toggle": [59,[9]],
		"/(components)/components/tooltip": [60,[9]],
		"/(components)/components/[teamIdentifier]": [27,[9,10]],
		"/(components)/components/[teamIdentifier]/[slug]": [28,[9,10]],
		"/(components)/components/[teamIdentifier]/[slug]/[slug]": [29,[9,10]],
		"/(components)/components/[teamIdentifier]/[slug]/[slug]/[slug]": [30,[9,10]],
		"/(corporate)/cookies": [68,[11]],
		"/(corporate)/fakturering": [~69,[11]],
		"/(corporate)/fragor-och-svar/[[cmsArticleCategoryName]]/[[articleId]]/[[articleName]]": [~70,[11]],
		"/(corporate)/helhetslosning": [~71,[11]],
		"/(corporate)/hemsida": [~72,[11]],
		"/(corporate)/integrationer-och-samarbeten": [73,[11]],
		"/(corporate)/integrationer-och-samarbeten/idrottonline": [74,[11]],
		"/(corporate)/kontakt": [75,[11]],
		"/(corporate)/lagkassa": [~76,[11]],
		"/(corporate)/medlemsregister": [~77,[11]],
		"/(corporate)/narvaro": [~78,[11]],
		"/(corporate)/nyfiken": [79,[11]],
		"/(corporate)/om-oss": [80,[11]],
		"/(corporate)/priser": [81,[11,13]],
		"/(corporate)/priser/korplag": [82,[11,13]],
		"/(corporate)/priser/medlemsforening": [83,[11,13]],
		"/(corporate)/referenser": [84,[11,14]],
		"/(corporate)/referenser/[sport]": [~85,[11,14]],
		"/(corporate)/sa-jobbar-vi": [86,[11]],
		"/sentry-example": [94],
		"/(corporate)/serviceavgifter": [87,[11]],
		"/(corporate)/sok": [~88,[11,15]],
		"/(corporate)/support": [89,[11]],
		"/(corporate)/tjana-pengar": [~90,[11]],
		"/(corporate)/tjana-pengar/[clientUrl]/[clientId]": [~91,[11]],
		"/(administration)/[teamIdentifier]/(pages)/grupper/gruppkostnader": [~16,[2,3,4,5]],
		"/(administration)/[teamIdentifier]/(pages)/grupper/lista": [~17,[2,3,4,6]],
		"/(administration)/[teamIdentifier]/(pages)/grupper/lista/[teamId]/gruppavgifter": [~18,[2,3,4,6,7]],
		"/(administration)/[teamIdentifier]/(pages)/grupper/lista/[teamId]/installningar": [~19,[2,3,4,6,7]],
		"/(administration)/[teamIdentifier]/(pages)/grupper/lista/[teamId]/medlemmar": [20,[2,3,4,6,7,8]],
		"/(administration)/[teamIdentifier]/(pages)/grupper/lista/[teamId]/undergrupper": [~21,[2,3,4,6,7]],
		"/(administration)/[teamIdentifier]/(pages)/grupper/lista/[teamId]/valkomstmejl": [~22,[2,3,4,6,7]],
		"/(site)/[teamIdentifier]/kalender/oversikt": [92],
		"/(site)/[teamIdentifier]/login": [~93],
		"/(administration)/[teamIdentifier]/(pages)/mejl/skicka": [~23],
		"/(administration)/[teamIdentifier]/(pages)/register/rapporter/betalande-medlemmar": [24,[2,3,4]],
		"/(administration)/[teamIdentifier]/(pages)/register/rapporter/medlemsrapport": [25,[2,3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';